<template>
  <div class="analysis_main_box wh100">
    <HnContent :hasData="true">
      <template slot="left">
        <ul class="menu_box">
          <li :class="[activeCode === item.path ? 'menu-active-bg-image baseColor' : 'menuItemBg']" v-for="item of menuList" @click="menuChange(item.path)" :key="item.code">
            <span :class="item.icon"></span>
            <span>{{ item.title }}</span>
          </li>
        </ul>
      </template>
      <template slot="right">
        <div class="content_box boxBg wh100" v-loading="changLoading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(40, 53, 63, 0.8)">
          <transition name="fade" mode="out-in">
            <!-- 同步分析 -->
            <template v-if="activeCode == '/yoy'">
              <content-box :dateInfo="yoyFormInfo" @tagClose="tagClose" @onDate="onDate" :contentInfo="contentEchartsList" :tagInfo="devInfoList">
                <el-cascader
                  style="width: 340px"
                  v-model="yoyDevInfo"
                  :options="yoyDevOption"
                  :props="{ multiple: true }"
                  placeholder="请选择同比设备"
                  collapse-tags
                  @visible-change="yoyDevChange"
                ></el-cascader>
              </content-box>
            </template>
            <!-- 环比分析 -->
            <template v-else-if="activeCode == '/qoq'">
              <content-box :isDate="false" :contentInfo="contentEchartsList" @tagClose="tagDateClose" :tagInfo="qoqFormInfo.dates">
                <el-date-picker
                  class="month_picker_box"
                  v-model="monthData"
                  format="yyyy 年 MM 月"
                  value-format="yyyy-MM"
                  @change="dateChange"
                  type="month"
                  :picker-options="setMonthDisabled"
                  placeholder="选择环比月份"
                />
                <!-- </el-date-picker> -->
                <!-- qoqFormInfo.devId -->
                <!-- :props="{ emitPath: false  }" yoyDevOption -->
                <el-cascader style="width: 340px" v-model="qoqFormInfo.devId" :options="qoqDevOption" :props="{ emitPath: false }" placeholder="请选择环比设备"></el-cascader>

                <el-button type="primary" @click="searchQoqData" size="medium" plain class="search_but_box" :loading="searchLoad">查询</el-button>
              </content-box>
            </template>
            <!-- 动物称重分析 -->
            <template v-else-if="activeCode == '/animalWeigh'">
              <div class="animal_content_box wh100">
                <div class="top_tools_box">
                  <el-select v-model="baseId" style="width: 300px" @visible-change="animalBaseChange" collapse-tags multiple placeholder="请选择基地">
                    <el-option v-for="item in baseOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                  <el-tag v-for="(tag, i) in baseTagList" :key="tag.label" @close="baseTagClose(tag.value)" class="tag_box" closable type="success">
                    {{ tag.label }}
                  </el-tag>
                </div>
                <div class="content_box">
                  <div class="pageBg">
                    <p class="baseBorderLeftColor">动物存栏量统计</p>
                    <div id="animalLiveStocks"></div>
                  </div>
                  <div class="pageBg">
                    <p class="baseBorderLeftColor">动物重量统计</p>
                    <div id="animalWeights"></div>
                  </div>
                  <div class="pageBg">
                    <p class="baseBorderLeftColor">动物养殖批次+死亡(无害化)数量统计</p>
                    <div id="animalBreedDeaths"></div>
                  </div>
                </div>
              </div>
            </template>
            <!-- 动物健康分析 -->
            <template v-else-if="activeCode == '/animalHealth'">
              <health-analysis
                @on-change="handleAnalysisChang"
                v-loading="changLoading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(40, 53, 63, 0.8)"
                :areaList="areaList"
                :areaId="areaId"
                :featureData="featureData"
                :stepsData="stepsData"
                :tempData="tempData"
              ></health-analysis>
            </template>
            <!-- 404 -->
            <template v-else>
              <div class="notData_box boxBg">
                <hn-emptyData text="暂无数据" size="200px" style="height: 100%" setType="col"></hn-emptyData>
              </div>
            </template>
          </transition>
        </div>
      </template>
    </HnContent>
  </div>
</template>

<script>
  import config from "@/config";
  import contentBox from "./components/contentBox.vue";
  import healthAnalysis from "./components/healthAnalysis.vue";
  import { optionAnimalPie, optionAnimalLine } from "@/libs/echartsOption";
  import { deepClone } from "@/libs/util";
  const getNewDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    return { year, month, day };
  };

  const getDate = (year, month, day) => {
    return new Date(year, month, day);
  };
  const yearMonthNowFun = () => {
    // 获取当前的月份信息
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth();
    let monthStr = month >= 1 && month <= 9 ? "0" + month : month;

    return {
      year: year.toString(),
      month: monthStr.toString(),
      yearNub: year,
      monthNub: month,
    };
  };
  const nowYearAndMonth = yearMonthNowFun();
  export default {
    components: {
      contentBox,
      healthAnalysis,
    },
    data() {
      let _that = this;

      return {
        featureData: null,
        stepsData: null,
        tempData: null,
        areaId: "",
        testdata: "",
        changLoading: false,
        // menuList:[

        // ],
        activeCode: "/yoy",
        dateInfo: {
          customData: null,
          fixedData: 1,
        },
        echartsList: [
          {
            data: null,
            dome: "test",
            echartOption: "test",
            title: "xx基地动物存栏量统计(头)",
          },
        ],
        yoyFormInfo: {
          devIds: null,
          sTime: "",
          eTime: "",
          timeType: 5,
        },
        searchLoad: false,
        animalInitData: null,
        baseOption: [],
        yoyDevOption: [],
        qoqDevOption: [],
        yoyDevInfo: null,
        devInfoList: [],
        contentEchartsList: [],
        areaList: [],
        qoqEchartsList: [],
        qoqFormInfo: {
          devId: "",
          dates: [],
        },
        monthData: "",
        setMonthDisabled: {
          disabledDate(time) {
            // 获取时间选择器的月份信息
            const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
            let timemonth = time.getMonth() + 1; // 与上面同理
            if (timemonth >= 1 && timemonth <= 9) {
              timemonth = "0" + timemonth;
            }

            const elTimeDataNub = timeyear.toString() + timemonth.toString();
            const elTimeDataStr = timeyear.toString() + "-" + timemonth.toString();

            // 拼接当前年月数据
            const nowDate = nowYearAndMonth.year + nowYearAndMonth.month;

            let reqDates = _that.qoqFormInfo.dates;
            const isDate = reqDates.includes(elTimeDataStr);
            return elTimeDataNub < nowDate && !isDate ? false : true;
          },
        },
        baseId: null,
        chartOption: {
          optionAnimalPie,
          optionAnimalLine,
        },
      };
    },
    watch: {
      devInfoList: {
        handler(val) {
          if (val && val.length) {
            let result = [],
              data = null;
            val.forEach((item) => result.push(item.value));
            data = result.length ? result : null;
            this.$set(this.yoyFormInfo, "devIds", data);
          } else {
            // 没有选择设备 清空内容
            this.$set(this.yoyFormInfo, "devIds", null);
            this.contentEchartsList = [];
          }
        },
        deep: true,
      },
    },
    created() {
      this.reqEntBaseAreaDeviceList();
      this.reqEntBaseAreaList();
    },
    computed: {
      baseTagList() {
        if (this.baseId && this.baseId.length) {
          const data = this.baseOption.filter((item) => this.baseId.includes(item.value));
          return data || [];
        } else return [];
      },
      menuList() {
        const menuList = this.$store.state.user.menuList;
        let result = [];
        menuList.some((item) => {
          if (item.path == "/analysis") {
            result = item.children || [];
            return true;
          }
        });
        if (result && result.length) this.menuChange(result[0].path);
        return result;
      },
    },
    methods: {
      menuChange(code) {
        this.activeCode = code;
        switch (code) {
          case "/yoy":
            this.reqIotDeviceYoyAnalysis();
            break;
          case "/qoq":
            this.reqIotDeviceQoqAnalysis();
            break;
          case "/animalWeigh":
            // 切换动画有加载延迟，进入动物分析模块时，需定时请求，
            setTimeout(() => {
              this.reqTraAnimalInfoAnalysis();
            }, 500);
            break;
          case "/animalHealth":
            this.reqAreaSelectList();
            break;
        }
      },
      animalBaseChange(isOpen) {
        if (!isOpen) {
          this.reqTraAnimalInfoAnalysis();
        }
      },
      // 初始化环境参数
      initQoqData(data) {
        const hanldeYM = (nub) => {
          let { yearNub, monthNub } = nowYearAndMonth;
          let prevMonth = getDate(yearNub, monthNub, 1);
          prevMonth.setMonth(prevMonth.getMonth() - nub);
          let result = getNewDate(prevMonth);
          let { year, month } = result;

          return `${year}-${month + 1}`;
        };
        let startDate = hanldeYM(2);
        let endDate = hanldeYM(1);
        this.$set(this.qoqFormInfo, "devId", data[0].children[0].value);

        this.$set(this.qoqFormInfo, "dates", [startDate, endDate]);
      },
      async reqEntBaseAreaList() {
        const res = await this.$api.EntBaseAreaList();
        if (res && res.length) {
          let result = [],
            baseId = [];
          res.forEach((item) => {
            result.push({
              value: item.baseId,
              label: item.baseName,
            });
            baseId.push(item.baseId);
          });

          this.baseOption = result;
          this.baseId = baseId;
        } else {
          this.baseOption = [];
        }
      },

      // 动物体重
      async reqTraAnimalInfoAnalysis() {
        this.changLoading = true;
        const res = await this.$api.TraAnimalInfoAnalysis({ baseId: this.baseId });
        setTimeout(() => {
          this.changLoading = false;
        }, 300);
        if (res) {
          Object.keys(res).forEach((key) => {
            let optionName = key == "animalLiveStocks" ? "optionAnimalPie" : "optionAnimalLine";

            this.echartInfo(key, res[key], optionName);
          });
        }
      },
      // 设备同比
      async reqIotDeviceYoyAnalysis() {
        this.changLoading = true;
        const res = await this.$api.IotDeviceYoyAnalysis(this.yoyFormInfo);
        setTimeout(() => {
          this.changLoading = false;
        }, 300);
        if (res && res.length) {
          let arrList = [];
          res.forEach((item) => {
            arrList.push({
              data: item,
              dome: "yoy",
              echartOption: "optionMoreline",
              title: `${item.paramName}(${item.paramUnit})`,
            });
          });
          this.contentEchartsList = arrList;
        } else {
          this.contentEchartsList = [];
        }
      },
      //  设备环比
      async reqIotDeviceQoqAnalysis() {
        try {
          this.changLoading = true;
          const res = await this.$api.IotDeviceQoqAnalysis(this.qoqFormInfo);
          setTimeout(() => {
            this.changLoading = false;
          }, 300);
          if (res && res.length) {
            let arrList = [];
            res.forEach((item) => {
              arrList.push({
                data: item,
                dome: "yoy",
                echartOption: "optionMoreline",
                title: `${item.paramName}(${item.paramUnit})`,
              });
            });
            this.contentEchartsList = arrList;
          } else {
            this.contentEchartsList = [];
          }
          return res;
        } catch {
          this.searchLoad = false;
          return false;
        }
      },
      // 基地区域设备
      async reqEntBaseAreaDeviceList() {
        const menuDevType = config.menuDevType;
        const res = await this.$api.GroupDeviceList({ devTypes: [menuDevType["采集设备"], menuDevType["采控设备"]] });

        if (res && res.length) {
          let result = [],
            defaultData = [],
            devInfoList = [];
          res.forEach((item, i) => {
            let arrList = {
              value: item.id,
              label: item.name,
              children: [],
            };
            if (item.devs && item.devs.length) {
              item.devs.forEach((ele) => {
                let data = {
                  value: ele.devId,
                  label: ele.devName,
                };
                if (i == 0) {
                  // 级联选择器默认值
                  defaultData.push([item.id, ele.devId]);
                  //  tag显示信息值
                  devInfoList.push(data);
                }

                arrList.children.push(data);
              });
            }
            result.push(arrList);
          });
          // 默认请求第一个基地下面的所有设备
          if (defaultData.length) {
            this.yoyDevInfo = defaultData;
            this.devInfoList = devInfoList;
          }
          if (result.length) this.initQoqData(result);
          this.yoyDevOption = deepClone(result);
          this.qoqDevOption = deepClone(result);
        }
      },
      echartInfo(dome, data, optionName) {
        let myChart = this.$echarts.init(document.getElementById(dome));

        myChart.setOption(this.chartOption[optionName](data), true);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
        // this.charts = myChart
      },
      tagClose(info) {
        let { data, i } = info;
        this.yoyDevInfo = this.yoyDevInfo.filter((item) => item[1] !== data.value);
        this.devInfoList.splice(i, 1);
      },
      tagDateClose(info) {
        let { i } = info;
        if (i > -1) {
          this.qoqFormInfo.dates.splice(i, 1);
          this.monthData = "";
        }
      },
      yoyDevChange(isColse) {
        if (!isColse) {
          let info = this.yoyDevInfo;
          if (info && info.length) {
            let result = [];
            info.forEach((data) => {
              this.yoyDevOption.some((item) => {
                if (item.value == data[0]) {
                  return item.children.some((val) => {
                    if (val.value == data[1]) {
                      result.push(val);
                      return true;
                    }
                  });
                }
              });
            });
            this.devInfoList = result;
          } else {
            this.devInfoList = [];
          }
        }
      },
      onDate(data) {
        if (data) this.yoyFormInfo = data;
        this.reqIotDeviceYoyAnalysis();
      },
      dateChange(val) {
        this.qoqFormInfo.dates.push(val);
      },
      async searchQoqData() {
        let { devId, dates } = this.qoqFormInfo;

        if (dates && dates.length < 2) {
          this.$msg.warning("环比参数需要两个以上月份，请选择两个以上月份。");
          return;
        }
        if (!devId) {
          this.$msg.warning("请选择环比参数！");
          return;
        }
        this.searchLoad = true;
        await this.reqIotDeviceQoqAnalysis();
        setTimeout(() => {
          this.searchLoad = false;
        }, 300);
      },
      baseTagClose(value) {
        let index = this.baseId.indexOf(value);
        this.baseId.splice(index, 1);
        this.reqTraAnimalInfoAnalysis();
      },

      // 获取动物体温分析
      async getAnimalTempData(id) {
        let res = await this.$api.TraAnimalInfoTTmpAnalyse({ id: id });
        if (res && res.length) {
          this.tempData = res[0];
        } else {
          this.tempData = null;
        }
      },

      // 获取动物步数统计
      async getAnimalStepsData(id) {
        let res = await this.$api.TraAnimalInfoStepNumber({ id: id });
        if (res && res.errcode !== 0) {
          this.stepsData = res;
        } else {
          this.stepsData = null;
        }
      },

      // 获取动物生理分析
      async getAnimalFeatureData(id) {
        let res = await this.$api.TraAnimalInfoPhysiology({ id: id });
        if (res && res.length) {
          let obj = {
            data: res,
          };
          this.featureData = obj;
        } else {
          this.featureData = null;
        }
      },

      // 获取动物围栏
      async reqAreaSelectList() {
        let res = await this.$api.EntBaseAreaList({ category: 1, areaType: 4 });
        if (res && res.length > 0) {
          let result = [];
          res.map((item) => {
            item.areas.map((items) => {
              result.push({
                value: items.areaId,
                label: items.areaName,
              });
            });
          });
          this.areaList = result || [];
          this.areaId = result[0].value;
          this.getAnimalTempData(result[0].value);
          this.getAnimalStepsData(result[0].value);
          this.getAnimalFeatureData(result[0].value);
        }
      },

      // 动物围栏选择
      handleAnalysisChang(val) {
        this.changLoading = true;
        this.getAnimalTempData(val);
        this.getAnimalStepsData(val);
        this.getAnimalFeatureData(val);
        setTimeout(() => {
          this.changLoading = false;
        }, 300);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .analysis_main_box {
    // display: flex;
    // box-sizing: border-box;
    // padding: 20px 20px 0 20px;

    .menu_box {
      width: 100%;
      & > li {
        width: 100%;
        height: 40px;
        font-size: 16px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        & > span:first-child {
          font-size: 22px;
          margin-right: 10px;
          text-indent: 40px;
        }
      }
      .menuItemBg {
        background: url("../../../assets/imgs/monitoring/defalut/navigation_bg.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .notData_box {
      flex: 1;
      height: 100%;
      border-radius: 8px;
      margin-left: 10px;
      box-sizing: border-box;
    }
    .content_box {
      border-radius: 8px;
      margin-left: 20px;
      box-sizing: border-box;
      padding: 20px;
      .month_picker_box {
        margin-right: 15px;
        border-radius: 5px;
      }
      .search_but_box {
        margin-left: 15px;
      }
      .animal_content_box {
        display: flex;
        flex-direction: column;
        .top_tools_box {
          width: 100%;
          height: 50px;
          margin-bottom: 10px;
          .tag_box {
            margin-left: 15px;
          }
        }
        .content_box {
          flex: 1;
          display: flex;
          justify-content: space-between;
          & > div {
            width: 32%;
            height: 420px;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
            padding: 10px 0;
            & > p {
              padding: 2px 0 2px 20px;
            }
            & > div {
              width: 100%;
              height: calc(100% - 25px);
            }
          }
        }
      }
    }
  }
</style>
