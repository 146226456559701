<template>
  <div class="health wh100">
    <div class="top_tools_box">
      <el-select filterable v-model="selectFenceId" style="width: 300px" @change="handleFenceChange" collapse-tags placeholder="请选择围栏">
        <el-option v-for="item in areaList" :key="item.id" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>
    <div class="content_box">
      <div v-if="featureData || stepsData || tempData">
        <div class="content_box_top">
          <div class="feature_box pageBg" style="margin-right: 20px">
            <p class="baseBorderLeftColor">生理分析</p>
            <div id="featureEcharts" v-if="featureData"></div>
            <div v-else class="wh100 empty_box_min">
              <img :src="noDataImg" alt="" />
              <p>暂无数据</p>
            </div>
          </div>
          <div class="feature_box pageBg">
            <p class="baseBorderLeftColor">步数分析</p>
            <div id="stepsEcharts" v-if="stepsData"></div>
            <div v-else class="wh100 empty_box_min">
              <img :src="noDataImg" alt="" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
        <div class="temp_box pageBg">
          <p class="baseBorderLeftColor">体温分析</p>
          <div id="tempEcharts" v-if="tempData"></div>
          <div v-else class="wh100 empty_box_min">
            <img :src="noDataImg" alt="" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
      <div v-else class="wh100 empty_box">
        <img :src="noDataImg" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { optionAnimalTemp, optionAnimalSteps, optionAnimalFeature } from "@/libs/echartsOption";
  export default {
    props: {
      areaList: {
        type: Array,
        default: () => [],
      },
      areaId: String,
      featureData: {
        type: Object,
      },
      stepsData: {
        type: Object,
      },
      tempData: {
        type: Object,
      },
    },
    data() {
      return {
        selectFenceId: "",
        noDataImg: require("@/assets/imgs/noData/no_data2.png"),
        chartOption: {
          optionAnimalTemp,
          optionAnimalSteps,
          optionAnimalFeature,
        },
      };
    },
    watch: {
      areaId: {
        handler(newVal, oldVal) {
          if (newVal !== oldVal) this.selectFenceId = newVal;
          this.$emit("onChange", newVal);
        },
        immediate: true,
      },
      featureData: {
        handler(val) {
          if (val) {
            this.$nextTick(() => {
              this.linEchartInfo("featureEcharts", "optionAnimalFeature", val);
            });
          }
        },
        immediate: true,
      },
      stepsData: {
        handler(val) {
          if (val) {
            this.$nextTick(() => {
              this.echartInfo("stepsEcharts", "optionAnimalSteps", val);
            });
          }
        },
        immediate: true,
      },
      tempData: {
        handler(val) {
          if (val) {
            this.$nextTick(() => {
              this.echartInfo("tempEcharts", "optionAnimalTemp", val);
            });
          }
        },
        immediate: true,
      },
    },

    methods: {
      // 选择围栏
      handleFenceChange(val) {
        this.$emit("on-change", val);
      },
      // 加载echarts
      echartInfo(dom, optionName, data) {
        let myChart = this.$echarts.init(document.getElementById(dom));
        window.addEventListener("resize", function () {
          myChart.resize();
        });
        myChart.setOption(this.chartOption[optionName](data), true);
      },
      linEchartInfo(dom, optionName, data) {
        let myChart = this.$echarts.init(document.getElementById(dom));
        window.addEventListener("resize", function () {
          myChart.resize();
        });
        myChart.setOption(this.chartOption[optionName](data), true);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .health {
    display: flex;
    flex-direction: column;
    .top_tools_box {
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
    }
    .content_box {
      flex: 1;
      height: 0;
      .content_box_top {
        display: flex;
      }
      .feature_box {
        box-sizing: border-box;
        width: 49.5%;
        height: 330px;
        border-radius: 5px;
        padding: 10px 0;
        & > p {
          padding: 2px 0 2px 20px;
        }
        & > div {
          width: 100%;
          height: calc(100% - 25px);
        }
      }

      .temp_box {
        box-sizing: border-box;
        margin-top: 20px;
        width: 100%;
        height: 350px;
        border-radius: 5px;
        padding: 10px 0;
        & > p {
          padding: 2px 0 2px 20px;
        }
        & > div {
          width: 100%;
          height: calc(100% - 25px);
        }
      }

      .empty_box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        & > p {
          margin-top: 30px;
        }
      }
      .empty_box_min {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        & > p {
          margin-top: 10px;
        }
      }
    }
  }
</style>
