<template>
    <div class="contentBox_main_box wh100">
      <div class="tools_box">
          <div class="slot_box">
              <slot></slot>
          </div>
          <div class="right_box">
              <template v-if="isDate">
                <div class="date_box">
                    <el-date-picker
                            v-model="dateForm.customData"
                            @change="searchParamDateChange"
                            class="date_picker_box"
                            type="daterange"
                            align="right"
                            size="medium"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                        <ul class="boxsubBg dateType_box">
                            <li 
                            :class="[dateForm.fixedData == item.code? 'active':'', 'weather-cp']"
                            v-for="item of picTimeList"
                            :key="item.code"
                            @click="paramTimeChange(item.code)"
                            >{{ item.name }}</li>
                        </ul>
                </div>
              </template>
              <template v-else>
                <div></div>
              </template>
              <!-- <el-button type="warning" size="medium" plain>导出</el-button> -->
          </div>
      </div>
      <transition name="fade">
        <template v-if="devInfoList.length">
            <div class="devList_box">
                <el-tag
                    v-for="(tag,i) in devInfoList"
                    :key="tag.label || tag"
                    @close='tagClose(tag,i)'
                    class="tag_box"
                    closable
                    type="success">
                    {{tag.label || tag}}
                </el-tag>
            </div>
        </template>
      </transition>
      <div class="content_box">
       
          <template v-if="contentInfo && contentInfo.length">
                <ul class="echarts_list_box boxScroll wh100">
                    <li v-for="(item,i) of contentInfo" :key="i"  class="pageBg">
                        <p class="baseBorderLeftColor">{{ item.title }}</p>
                        <div :id="item.dome+i"></div>
                    </li>
                </ul>
          </template>
          <template v-else>
               <div class="wh100">
                   <img :src="noDataImg" alt="">
                   <p>暂无数据</p>
               </div>
          </template>
          
      </div>
    </div>
</template>

<script>
    import { optionMoreline } from '@/libs/echartsOption'
    
    export default {
        props:{
           dateInfo: Object,
           contentInfo:{
               type: Array,
               default: ()=> []
           },
           isDate:{
               type: Boolean,
               default: true
           },
           tagInfo:{
              type: Array,
              default: ()=> [] 
           }
        },
        data(){
            return {
             
                picTimeList:[
                   {
                       name: '今天',
                       code: 1
                   },
                    {
                       name: '三天',
                       code: 2
                   },
                   {
                       name: '七天',
                       code: 3
                   },
                   {
                       name: '近一月',
                       code: 4
                   },
                   {
                       name: '近三月',
                       code: 5
                   },
                ],
                dateForm: {
                    customData: null,
                    fixedData: 5,
                },
                devInfoList: [],
                chartOption:{
                    optionMoreline,
                },
                noDataImg: require('@/assets/imgs/noData/no_data2.png')
            }
        },
        created(){
           
          let dateInfo = this.dateInfo
          if(dateInfo){
              let data = {
                  customData: [dateInfo.sTime, dateInfo.eTime],
                  fixedData: dateInfo.timeType,
              }
              this.dateForm = data   
          }
          if( this.contentInfo.length ) this.loopData(this.contentInfo)
          this.devInfoList = this.tagInfo   
          
        
        },
        watch:{
           tagInfo:{
               handler(val){
                    
                    this.devInfoList = val
               },
               deep: true
           },
           contentInfo:{
               handler(val){
                   this.loopData(val)
               },
               deep: true
           }
        },
        methods:{
          loopData(list){
                
             if(list.length){
                 list.forEach((item,i) => {
                     this.$nextTick(()=> {
                         this.echartInfo(item,i)
                     })
                     
                 })
             }
          }, 
          echartInfo(item,i){
                
                let myChart = this.$echarts.init(document.getElementById(item.dome+i))
            
                myChart.setOption(this.chartOption[item.echartOption](item.data),true)
                // window.addEventListener("resize", function () {
                //     myChart.resize();          
                // })
                // this.charts = myChart
          },
           // 参数历史数据 自定义日期选择
          searchParamDateChange(data){
             
              if(data && data.length == 2){
                  let [sTime,eTime] = data
                  let timeInfo = [sTime,eTime]
                  this.$set(this.dateForm,'customData',timeInfo)
                  this.$set(this.dateForm,'fixedData',0)
                  let resultData = Object.assign({},this.dateInfo,{timeType: 0},{sTime,eTime})
                  this.$emit('onDate',resultData)
                   
              }
               
          }, 
          // 参数历史数据 固定日期选择
          paramTimeChange(code) {
              if(code){
                  this.$set(this.dateForm,'customData',null)
                  this.$set(this.dateForm,'fixedData',code)
                  let resultData = Object.assign({},this.dateInfo,{timeType: code},{sTime:'',eTime: ''})
                  this.$emit('onDate',resultData)
              }
          },
          //  关闭设备
          tagClose(data,i){
            //  this.devInfoList
             this.$emit('tagClose',{ data,i}) 
          }  
        }
    }
</script>

<style lang="scss" scoped>
 .contentBox_main_box{
     display: flex;
     flex-direction: column;

     .tools_box{
         width: 100%;
         height: 50px;
         display: flex;
         align-items: center;
         margin-bottom: 10px;
         .slot_box{
             
         }
         .right_box{
             flex: 1;
             min-width: 0;
             margin-left: 120px;
             display: flex;
             justify-content: space-between;
             align-items: center;
             .date_box{
                display: flex;
                align-items: center;  
                .date_picker_box{
                    margin-right: 15px;
                }
                ul.dateType_box{
                    display: flex;
                    //    width: 214px;
                    height: 34px;
                    line-height: 34px;
                    font-size: 12px;
                    color: #fff;
                    border-radius: 17px;
                    & > li {
                        padding: 0 28px;
                        border-radius: 17px;
                        
                    }
                    & > li:not(:last-child){
                        margin-right: 8px;
                    }
                
                }
             }
         }
     }
     .devList_box{
         width: 100%;
        //  height: 40px;
         margin-bottom: 10px;
         .tag_box{
            margin-right: 10px;
            margin-top: 5px;
         }
     }
     .content_box{
         flex: 1;
         height: 0;
        //  height: calc(100% - 60px);
         .echarts_list_box{
             
             & > li:not(:first-child){
                margin-top: 20px;
             }
             & > li {
               width: 100%;
               height: 300px;
               border-radius: 5px;
               padding: 10px 0;
               & > p {
                   padding: 2px 0 2px 20px;
                  
               }
               & > div{
                   width: 100%;
                   height: calc(100% - 25px);
               }
             }
             
         }
         & > div {
             display: flex;
             align-items: center;
             justify-content: center;
             flex-direction: column;
             & > p {
                 margin-top: 30px;
             }
         }
     }
 }
</style>